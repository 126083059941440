define('js/custom',['jquery'], function($) {

    document.addEventListener("DOMContentLoaded", function() { 
        let mainnav = document.getElementById("store.menu");
        let addmenurole = mainnav.getElementsByTagName("li");
        for (let i = 0; i < addmenurole.length; i++) {
            addmenurole[i].setAttribute("role","presentation");
        }
    });


    $(document).ready(function(){
        $(window).resize(function () {
            var products = '.products-grid .product-item';
            jQuery(products).each(function () {
                var divHeight = $(this).find('.product-image-container').outerHeight(); 
                $(this).find('a.weltpixel-quickview.weltpixel_quickview_button_v1').css('top', divHeight - 60); 
            });
                
        });
    });



});

